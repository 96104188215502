import { Link, useNavigate } from "react-router-dom";

import { Keyring } from "@polkadot/api";
import moment from "moment";

const TransferPreview = ({ transfer }) => {
  const navigate = useNavigate();
  const {
    blockNumber,
    extrinsicIdx,
    toMultiAddressAccountId,
    fromMultiAddressAccountId,
    value,
    blockDatetime,
  } = transfer;

  const toMultiAddressAccounts = toMultiAddressAccountId.split(",");
  const fromMultiAddressAccounts = fromMultiAddressAccountId.split(",");
  const to = [];
  const from = [];

  for (let i = 0; i < toMultiAddressAccounts.length; i++) {
    const keyring = new Keyring({ ss58Format: 71, type: "sr25519" });
    const decodedToAcc = keyring.encodeAddress(toMultiAddressAccounts[i], 71);
    to.push(decodedToAcc);
  }

  for (let i = 0; i < fromMultiAddressAccounts.length; i++) {
    const keyring = new Keyring({ ss58Format: 71, type: "sr25519" });
    const decodedFromAcc = keyring.encodeAddress(
      fromMultiAddressAccounts[i],
      71
    );
    from.push(decodedFromAcc);
  }

  const openAccount = (e, account) => {
    e.preventDefault();
    navigate("account/" + account);
  };

  return (
    <Link to={"/extrinsic/" + blockNumber + "-" + extrinsicIdx}>
      <div className="home-list-inner">
        <div className="home-list-left">
          <div className="list-left-info mb5">
            Extrinsic#
            <span>{blockNumber + "-" + extrinsicIdx}</span>
          </div>
          <div className="list-left-info">
            From{" "}
            {from.map((acc, index) => (
              <span
                key={index}
                className="ellipsis"
                title={acc}
                onClick={(e) => openAccount(e, acc)}
              >
                {acc}
              </span>
            ))}{" "}
            to{" "}
            {to.map((acc, index) => (
              <span
                key={index}
                className="ellipsis"
                title={acc}
                onClick={(e) => openAccount(e, acc)}
              >
                {acc}
              </span>
            ))}
          </div>
        </div>
        <div className="home-list-right">
          <div className="transfer-value mb5">{value / 1000000000000} P3D</div>
          <div className="home-timestamp">
            {moment(blockDatetime).fromNow()}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TransferPreview;
